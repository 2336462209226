import { useApiClient } from '@/api'
import type { FileDetailsFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

const fileNames = ref('')
const files = ref<FileList | null>()
const data = reactive({ fileNames, files })

export function useFileUploadForm() {
  const { uploadFile } = useApiClient()

  const { validate, errors } = useValidation({
    files: { value: files, required: true, validators: [relatedFileValidator] },
  })

  async function relatedFileValidator(files?: FileList | null) {
    const maxBytes = 100 * 1024 * 1024 // 100 MB
    const totalSize = Array.from(files ?? []).reduce((acc, file) => acc + file.size, 0)
    if (totalSize > maxBytes) return 'Total file size is too large'
    return undefined
  }

  async function submit(): Promise<FileDetailsFragment[] | undefined> {
    const valid = await validate()
    if (!valid) return
    const promises = Array.from(files.value!).map((file) => uploadFile(file))
    return await Promise.all(promises)
  }

  function reset() {
    fileNames.value = ''
    files.value = undefined
  }

  return { data, reset, submit, errors }
}
