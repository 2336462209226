import { useApiClient } from '@/api'
import { PaymentMethodEnum, SubscriptionIntervalEnum, SubscriptionLevelEnum } from '@/generated/sdk'
import { useAddressForm } from '@/user'
import { useValidation } from '@madxnl/dodo-ui'
import { ClientError } from 'graphql-request'
import type { InjectionKey } from 'vue'
import { computed, inject, provide, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useChargebeePayment, useValidateEmail, useValidatePassword } from '.'
import { authLinks } from '../routes'

enum Steps {
  Account,
  Subscription,
  BillingPayment,
}
const StepTitles = {
  0: 'My Twin AI',
  1: 'Your plan',
  2: 'Billing information',
} as const

const ERROR_EMAIL_EXISTS = '#0301'

export function createSignupForm() {
  const { client } = useApiClient()
  const router = useRouter()

  const { validatePassword } = useValidatePassword()
  const { validateEmail } = useValidateEmail()
  const { getAuthorizedPaymentIntentId, validCreditCard } = useChargebeePayment()

  const organizationAddress = useAddressForm()
  const billingAddress = useAddressForm()

  const step = ref<Steps>(Steps.Account)
  const skipPayment = ref(false)
  const authorizedIntentId = ref(null)
  const organizationAddressSameAsBilling = ref(true)

  const data = reactive({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    organizationName: '',
    subscriptionLevel: undefined as SubscriptionLevelEnum | undefined,
    subscriptionInterval: SubscriptionIntervalEnum.Monthly,
    paymentMethod: PaymentMethodEnum.IDeal,
    paymentProvider: '',
    cardName: '',
  })

  const paymentInfoCompleted = computed(() =>
    data.paymentMethod === PaymentMethodEnum.CreditCard ? validCreditCard.value : true,
  )

  const { validate, errors } = useValidation({
    email: {
      value: computed(() => data.email),
      required: true,
      isEmail: true,
      maxLen: 50,
      validators: [validateEmail],
    },
    password: {
      value: computed(() => data.password),
      required: true,
      minLen: 6,
      maxLen: 30,
      validators: [validatePassword],
    },
    firstName: { value: computed(() => data.firstName), required: true, maxLen: 50 },
    lastName: { value: computed(() => data.lastName), maxLen: 50 },
    organizationName: { value: computed(() => data.organizationName), required: true, maxLen: 50 },
    subscriptionLevel: { value: computed(() => data.subscriptionLevel), required: true },
    cardName: { value: computed(() => data.cardName), required: true, maxLen: 50 },
    paymentProvider: { value: computed(() => data.paymentProvider), required: true },
  })

  async function submit() {
    try {
      if (!skipPayment.value) {
        authorizedIntentId.value = await getAuthorizedPaymentIntentId(data)
      }

      await client.createOrganization({
        data: {
          name: data.organizationName,
          subscriptionLevel: data.subscriptionLevel,
          subscriptionInterval: data.subscriptionInterval,
          paymentIntentId: authorizedIntentId.value,
          address: { ...organizationAddress.data },
          billingAddress: { ...billingAddress.data },
          email: data.email,
          users: [
            {
              email: data.email,
              password: data.password,
              firstName: data.firstName,
              lastName: data.lastName,
            },
          ],
        },
      })
    } catch (err) {
      if (err instanceof ClientError && err.message.includes(ERROR_EMAIL_EXISTS)) {
        errors.email = 'Email address already exists, try signing in instead.'
        return
      }
      throw err
    }
    data.password = ''
    await router.push({ ...authLinks.activationRequired(), query: { email: data.email } })
  }

  async function submitStep() {
    if (step.value === Steps.Account) {
      const valid = await validate('email', 'password', 'firstName', 'lastName', 'organizationName')
      // Reset skipPayment if validation fails
      if (!valid) return (skipPayment.value = false)
    } else if (step.value === Steps.Subscription) {
      const valid = await validate('subscriptionLevel')
      if (!valid) return
      // Skip payment if subscription level is simple
      skipPayment.value = data.subscriptionLevel === SubscriptionLevelEnum.Simple
    } else {
      // Check if billing address and organization address are valid
      const validBillingAddress = await billingAddress.validate()
      const validOrganisationAddress = organizationAddressSameAsBilling.value
        ? (organizationAddress.data = { ...billingAddress.data })
        : await organizationAddress.validate()

      // Check if payment details are valid
      const validPaymentDetails =
        data.paymentMethod === PaymentMethodEnum.IDeal ? await validate('paymentProvider') : await validate('cardName')

      if (!validBillingAddress || !validOrganisationAddress || !validPaymentDetails) return
    }

    if (step.value === Steps.BillingPayment || skipPayment.value) {
      await submit()
    } else {
      step.value++
    }
  }

  async function submitWithoutPayment() {
    // Set subscription level to simple
    data.subscriptionLevel = SubscriptionLevelEnum.Simple
    // Skip payment if subscription level is simple
    skipPayment.value = true
    await submitStep()
  }

  function previousStep() {
    step.value--
  }

  return {
    data,
    errors,
    validate,
    step,
    previousStep,
    submitStep,
    submitWithoutPayment,
    billingAddress,
    organizationAddress,
    organizationAddressSameAsBilling,
    Steps,
    StepTitles,
    paymentInfoCompleted,
  }
}

const key: InjectionKey<ReturnType<typeof createSignupForm>> = Symbol()
export function useSignupForm() {
  // Inject or provide the form so only one instance is active at a time
  const form = inject(key, createSignupForm, true)
  provide(key, form)
  return form
}
