<script setup lang="ts">
import SearchableList from '@/ui/components/SearchableList.vue'
import { Dropdown, TextInput } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useWorkflowEditor } from '../composables'
import { useDataSources } from './useDataSources'

const { getAvailableDataSources } = useDataSources()
const { selectedBlock } = useWorkflowEditor()

const props = defineProps<{
  value: string | null
  disabled: boolean
}>()

const emit = defineEmits<{
  change: []
  select: [reference: string]
}>()

const open = ref(false)

const groupedOptions = computed(() => {
  const groups = getAvailableDataSources(selectedBlock.value)
  return groups
    .map((group) => ({
      groupName: group.groupName,
      items: group.references.map((ref) => ({
        name: ref.label,
        id: ref.value,
        currentValue: ref.value === props.value,
      })),
    }))
    .filter((group) => group.items.length > 0)
})

const currentLabel = computed(() => {
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === props.value)
  return option?.name ?? ''
})

function select(reference: string) {
  emit('select', reference)
  open.value = false
}
</script>

<template>
  <Dropdown v-model:open="open">
    <template #trigger="{ toggle }">
      <TextInput
        :model-value="currentLabel"
        :readonly="!disabled"
        :disabled="disabled"
        :class="$style.input"
        @click="toggle"
      />
    </template>

    <template #content="{ close }">
      <SearchableList header="Select data source" :grouped-options="groupedOptions" @select="select" @close="close" />
    </template>
  </Dropdown>
</template>

<style module>
.input {
  width: 100%;
  cursor: pointer;
  background-color: color-mix(in lab, var(--dodo-color-info) 5%, transparent);
}
</style>
