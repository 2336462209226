import { useApiClient } from '@/api'
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { useConfirmDelete } from '@/ui/composables'
import { computed, ref } from 'vue'
import { useBlockTypes } from './useBlockTypes'
import { useEditorState } from './useEditorState'
import { useWorkflowDetails } from './useWorkflowDetails'
import type { EditorBlockExposed } from '../block/EditorBlock.vue'
// TODO: Add ErrorCollection again when API is ready
// import {  WorkflowErrorCollectionFragment } from '@/generated/sdk'

const gridSize = 32 // Size of the grid in pixels

const selectedBlockId = ref<string>()
const editorBlocks = ref<EditorBlockExposed[]>([])
const sidebarTab = ref<'general' | 'settings' | 'output'>('general')

export function useWorkflowEditor() {
  const { client } = useApiClient()
  const { workflow, refreshWorkflow, saveWorkflowImmediate, savePendingChanges } = useWorkflowDetails()
  const { getBlockTypeDetails, fetchBlockType, fetchBlockTypeByName } = useBlockTypes()
  const { getBlockPosition, setBlockPosition } = useEditorState()
  const { confirmDelete } = useConfirmDelete()

  const selectedBlock = computed({
    get() {
      return workflow.value?.workflowBlocks.find((block) => block.id === selectedBlockId.value)
    },
    set(block?: WorkflowBlockItemFragment) {
      selectedBlockId.value = block ? block.id : undefined
    },
  })

  const selectedBlockTypeDetails = computed(() => getBlockTypeDetails(selectedBlock.value?.blockConfig.block))

  function getBlock(blockId: string) {
    return workflow.value?.workflowBlocks.find((block) => block.id === blockId) ?? null
  }

  const selectedBlockName = computed(() => {
    return selectedBlock.value?.name || selectedBlockTypeDetails.value.readableName
  })

  function getPreviousBlocks(block: { previousBlocks?: { id: string }[] | null }) {
    return block?.previousBlocks?.map(({ id }) => getBlock(id)!).filter(Boolean) ?? []
  }

  function getNextBlocks(block: { nextBlocks?: { id: string }[] | null }) {
    return block?.nextBlocks?.map(({ id }) => getBlock(id)!).filter(Boolean) ?? []
  }

  async function addNewBlock(
    args: {
      nextBlocks?: { id: string }[]
      previousBlocks?: { id: string }[]
      condition?: string
      pos?: [number, number]
      blockType?: WorkflowBlockType
      blockConfigBlock?: string
    },
    opts: { select: boolean },
  ) {
    await savePendingChanges()
    const block = args.blockConfigBlock ?? (await fetchBlockTypeByName('prompt')).id
    const name = await newBlockName(block)
    const { createWorkflowBlock: newBlock } = await client.createWorkflowBlock({
      input: {
        name,
        blockType: args.blockType ?? WorkflowBlockType.Normal,
        condition: args.condition,
        blockConfig: {
          block,
          arguments: [],
          runs: [],
        },
        workflow: { id: workflow.value!.id },
        nextBlocks: args.nextBlocks,
        previousBlocks: args.previousBlocks,
      },
    })
    await refreshWorkflow()

    // Set the new block position
    const prevBlockId = args.previousBlocks?.[0]?.id
    const nextBlockId = args.nextBlocks?.[0]?.id
    const prevBlock = prevBlockId ? getBlock(prevBlockId) : null
    const prevBlockPosition = prevBlockId ? getBlockPosition(prevBlockId) : null
    const nextBlockPosition = nextBlockId ? getBlockPosition(nextBlockId) : null
    const parentIsSwitch = prevBlock?.blockType === WorkflowBlockType.Switch
    const parentIsIfElse = prevBlock?.blockType === WorkflowBlockType.IfElse
    const parentIsLoop = prevBlock?.blockConfig?.loop != null
    const S = gridSize
    let gridXY: [number, number] = args.pos ?? [0, 0]
    if (prevBlockPosition) {
      const [px, py] = prevBlockPosition
      if (prevBlockPosition && parentIsLoop) {
        gridXY = [px, py + 10 * S]
      } else if (prevBlockPosition && parentIsSwitch) {
        gridXY = [px + 20 * S, py + 4 * S]
      } else if (prevBlockPosition && parentIsIfElse) {
        gridXY = [px + (args.condition === 'true' ? -8 : 8) * S, py + 8 * S]
      } else if (prevBlockPosition) {
        gridXY = [px, py + 6 * S]
      }
    } else if (nextBlockPosition) {
      const [nx, ny] = nextBlockPosition
      gridXY = [nx, ny - 6 * S]
    }
    // Shift position if the new block is too close to another block
    for (let i = 0; i < 20; ++i) {
      const tooClose = editorBlocks.value.some((b) => {
        const [dx, dy] = [gridXY[0] - b.position[0], gridXY[1] - b.position[1]]
        return dx * dx + dy * dy < S * S
      })
      if (!tooClose) break
      gridXY = [gridXY[0] + 1, gridXY[1] + 1]
    }

    setBlockPosition(newBlock.id, gridXY)
    await saveWorkflowImmediate() // Save the new block position

    if (opts.select) selectBlock(newBlock)
    return newBlock
  }

  async function deleteConnection(from: { id: string }, to: { id: string }) {
    await savePendingChanges()
    await client.removeNextBlock({ blockId: from.id, nextBlockId: to.id })
    await refreshWorkflow()
  }

  function selectBlock(block: WorkflowBlockItemFragment) {
    selectedBlock.value = block
    sidebarTab.value = 'general'
  }

  async function removeBlock(block: WorkflowBlockItemFragment) {
    if (!workflow.value?.draft) return
    const confirmed = await confirmDelete('this block')
    if (!confirmed) return
    await savePendingChanges()
    await client.deleteWorkflowBlock({ workflowBlockId: block.id })
    await refreshWorkflow()
    // Clear selected block if it was removed
    if (selectedBlock.value?.id === block.id) selectedBlock.value = undefined
  }

  async function connectBlocks(from: { id: string }, to: { id: string; condition?: string | null }) {
    await savePendingChanges()
    await client.updateWorkflowBlock({ input: { id: from.id, nextBlocks: [{ id: to.id, condition: to.condition }] } })
    await refreshWorkflow()
  }

  function getBlockOutputConditions(block: WorkflowBlockItemFragment) {
    const nextBlocks = getNextBlocks(block)
    const conditionsSet = new Set(nextBlocks?.map((b) => b.condition))
    const conditions = Array.from(conditionsSet)
    conditions.sort((a, b) => (a === 'true' ? -1 : a === 'false' ? 1 : `${a}`.localeCompare(`${b}`)))
    return conditions
  }

  async function newBlockName(block: string) {
    const blockName = (await fetchBlockType(block))?.readableName // Ensure the block type is fetched
    let n = 1
    for (let i = 0; i < 99 && checkNameExists(`${blockName} ${n}`); i++) {
      n += 1
    }
    return `${blockName} ${n}`
  }

  function checkNameExists(name: string, blockId?: string) {
    return workflow.value?.workflowBlocks.some((block) => block.name === name && block.id !== blockId)
  }

  // TODO: Add ErrorCollection again when API is ready
  // const validationResult = ref<WorkflowErrorCollectionFragment>()
  //
  // async function queryWorkflowErrors() {
  //   const workflowId = workflow.value!.id
  //   const response = await client.validateWorkflowConfig({ workflowId })
  //   validationResult.value = response.validateWorkflowConfig
  //   return validationResult.value
  // }

  return {
    addNewBlock,
    selectBlock,
    connectBlocks,
    // validationResult,
    // queryWorkflowErrors,
    deleteConnection,
    selectedBlock,
    sidebarTab,
    removeBlock,
    getBlock,
    getBlockTypeDetails,
    selectedBlockName,
    getPreviousBlocks,
    getNextBlocks,
    getBlockOutputConditions,
    editorBlocks,
    gridSize,
    checkNameExists,
  }
}
