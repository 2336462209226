<script setup lang="ts">
import type { BlockConfigFragment, WorkflowItemFragment } from '@/generated/sdk'
import { DisplayDate, RelatedObjectField } from '@/ui/components'
import { useWorkflows } from '@/workflows/overview/useWorkflows'
import { FormItem, Row } from '@madxnl/dodo-ui'
import { useRouter } from 'vue-router'
import { useEditorLinks, useWorkflowDetails } from '../composables'

const { showMore, workflows, search, createNew } = useWorkflows()
const { workflow, triggerSaveWorkflow, saveWorkflowImmediate } = useWorkflowDetails()
const router = useRouter()
const { linkSubworkflowEdit } = useEditorLinks()

const props = defineProps<{
  blockConfig: BlockConfigFragment
}>()

async function goToItem(subworkflow: WorkflowItemFragment) {
  await router.push(linkSubworkflowEdit(workflow.value!.id, subworkflow.id))
}

async function newSubworkflow() {
  // Create a new workflow and set it as the subworkflow, then navigate to it
  if (!workflow.value) return
  const newWorkflow = await createNew()
  props.blockConfig.workflow = newWorkflow
  await saveWorkflowImmediate()
  await goToItem(newWorkflow)
}
</script>

<template>
  <FormItem label="Subworkflow">
    <RelatedObjectField
      v-model="props.blockConfig.workflow"
      v-model:search="search"
      placeholder="Subworkflow"
      :items="workflows"
      :show-more="showMore"
      :get-current-text="(item) => item.name"
      :action-edit="goToItem"
      :action-create="newSubworkflow"
      :disabled="!workflow?.draft"
      @update:model-value="triggerSaveWorkflow"
    >
      <template #item="{ item }">
        <Row>
          <h4>{{ item.name }}</h4>
          <span class="dodo-fade-secondary"><DisplayDate :date="+item.createdAt" /></span>
        </Row>
      </template>
    </RelatedObjectField>
  </FormItem>
</template>

<style module></style>
