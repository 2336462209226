import type { BlockConfigDetailsFragment, BlockItemFragment, WorkflowDetailsFragment } from '@/generated/sdk'
import { ref, watch } from 'vue'
import { useBlockTypes } from '../composables'

type Mode = 'input' | 'output' | 'settings'

const workflow = ref<WorkflowDetailsFragment>()
const blockConfig = ref<BlockConfigDetailsFragment>()
const mode = ref<Mode | undefined>('settings')
const blockType = ref<BlockItemFragment | null>()

export function useBlockSettingsContext() {
  // This function is used to set the current workflow/blockConfig of the block settings

  function setContext(
    _workflow: WorkflowDetailsFragment | undefined,
    _mode: Mode | undefined,
    _blockConfig: BlockConfigDetailsFragment | undefined,
  ) {
    workflow.value = _workflow
    mode.value = _mode
    blockConfig.value = _blockConfig
  }
  const { fetchBlockType } = useBlockTypes()

  watch(blockConfig, async () => {
    blockType.value = await fetchBlockType(blockConfig.value?.block)
  })

  return { setContext, workflow, blockConfig, blockType, mode }
}
