import { useValidation } from '@madxnl/dodo-ui'
import type { Ref } from 'vue'
import { reactive, ref } from 'vue'
import { useApiClient } from '@/api'

const ERROR_USER_NOT_FOUND = '#0305'

type Message = {
  code: 'info' | 'warning' | 'danger' | 'success'
  message: string
}

export function useFogotPasswordForm() {
  const { client } = useApiClient()

  const email = ref('')
  const data = reactive({ email })
  const submitted = ref(false)
  const submitMessage: Ref<Message | undefined> = ref()

  const { errors, validate } = useValidation({
    email: { value: email, required: true, isEmail: true, maxLen: 50 },
  })

  async function submit() {
    submitMessage.value = undefined
    const valid = await validate()
    if (!valid) return

    try {
      const { requestPasswordReset } = await client.requestPasswordReset(data)
      if (requestPasswordReset) {
        submitMessage.value = {
          code: 'success',
          message: 'Password reset instructions have been sent to your email address.',
        }
        submitted.value = true
      }
      return requestPasswordReset
    } catch (err) {
      if (err instanceof Error && err.message.includes(ERROR_USER_NOT_FOUND)) {
        submitMessage.value = {
          code: 'danger',
          message: 'User not found. Please check your email address.',
        }
        return
      }
      throw err
    }
  }

  return { data, submit, errors, submitMessage, submitted }
}
