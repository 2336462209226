<script setup lang="ts">
import SearchableList from '@/ui/components/SearchableList.vue'
import { Dropdown, Select } from '@madxnl/dodo-ui'
import { computed } from 'vue'

const props = defineProps<{
  value?: string
  options?: {
    name: string
    id: string
  }[]
}>()

const emit = defineEmits<{
  select: [reference: string]
}>()

const groupedOptions = computed(() => {
  const items =
    props.options?.map((option) => ({
      name: option.name,
      id: option.id,
      currentValue: option.id === props.value,
    })) || []
  return [{ groupName: null, items }]
})

const currentLabel = computed(() => {
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === props.value)
  return option?.name ?? ''
})

function select(option: string, close: () => void) {
  emit('select', option)
  close()
}

const options = computed(() => {
  const label = props.value ? currentLabel.value : ''
  return [{ label, value: props.value ?? null }]
})
</script>

<template>
  <Dropdown>
    <template #trigger="{ toggle }">
      <Select
        :model-value="value"
        :options="options"
        style="flex-grow: 1"
        @click="toggle"
        @keydown.space.prevent="toggle"
        @mousedown.prevent
      />
    </template>
    <template #content="{ close }">
      <SearchableList
        header="Previous input"
        :grouped-options="groupedOptions"
        @close="close"
        @select="(e) => select(e, close)"
      />
    </template>
  </Dropdown>
</template>
