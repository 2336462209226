import type { AddressDetailsFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'

export function useAddressForm() {
  // Reuseable address form validation

  const street1 = ref('')
  const street2 = ref('')
  const city = ref('')
  const state = ref('')
  const zip = ref('')
  const country = ref('')
  const id = ref<string>()
  const data = reactive({ street1, street2, city, state, zip, country, id })

  const { validate, errors } = useValidation({
    street1: { value: street1, required: true },
    street2: { value: street2 },
    city: { value: city, required: true },
    state: { value: state },
    zip: { value: zip, required: true },
    country: { value: country, required: true },
  })

  function loadData(address: AddressDetailsFragment | null) {
    if (address) {
      Object.assign(data, address)
    } else {
      Object.assign(data, {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        id: undefined,
      })
    }
  }

  return { data, validate, errors, loadData }
}
