import type { App, InjectionKey } from 'vue'
import { inject, markRaw, ref } from 'vue'
import type { NavEntry } from '../components'

export function createNavEntries() {
  const navEntries = ref<NavEntry[]>([])

  function addNavEntry(item: NavEntry) {
    if ('component' in item) {
      item.component = markRaw(item.component as object)
    }
    navEntries.value.push(item)
    navEntries.value.sort((a, b) => (a.order || 0) - (b.order || 0))
  }

  function install(app: App) {
    app.provide(navEntriesKey, service)
  }

  const service = { navEntries, addNavEntry, install }

  return service
}

export type NavEntries = ReturnType<typeof createNavEntries>

export const navEntriesKey: InjectionKey<NavEntries> = Symbol('navEntries')

export function useNavEntries() {
  const entries = inject(navEntriesKey)
  if (!entries) throw new Error('Navbar plugin is not installed')
  return entries
}
