import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { ref } from 'vue'
import { useWorkflowEditor } from './useWorkflowEditor'

const dragConnectState = ref<{
  blockId: string
  fromInput: boolean
  condition: undefined | string
}>()

export function useDragToConnect() {
  // Connect blocks by dragging a line from one block to another

  const { connectBlocks } = useWorkflowEditor()

  function beginDragConnect(props: { workflowBlockId: string; isInput: boolean; condition?: string }) {
    dragConnectState.value = {
      fromInput: props.isInput,
      condition: props.condition,
      blockId: props.workflowBlockId,
    }
    document.addEventListener('pointerup', pointerup)
  }

  function pointerup() {
    document.removeEventListener('pointerup', pointerup)
    dragConnectState.value = undefined
  }

  async function endDragConnect(workflowBlock: WorkflowBlockItemFragment) {
    // Release drag connect on top of this block
    if (!dragConnectState.value) return
    const { blockId, condition, fromInput } = dragConnectState.value
    dragConnectState.value = undefined
    const isIfElse = workflowBlock.blockType === WorkflowBlockType.IfElse
    if (blockId === workflowBlock.id) return
    if (fromInput) {
      const condition = isIfElse ? 'true' : undefined
      await connectBlocks({ id: workflowBlock.id }, { id: blockId, condition })
    } else {
      await connectBlocks({ id: blockId }, { id: workflowBlock.id, condition })
    }
  }

  return { dragConnectState, beginDragConnect, endDragConnect }
}
