<script lang="ts" setup>
import type { FileDetailsFragment } from '@/generated/sdk'
import { Button, FormItem, TextInput } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { LargeModalLayout } from '.'
import { useSimpleMessage } from '../composables'
import { useFileUploadForm } from './useFileUploadForm'

const props = defineProps<{
  open: boolean
  multiple: boolean
}>()

const emit = defineEmits<{
  close: []
  filesCreated: [file: FileDetailsFragment[]]
}>()

const { data, reset, submit, errors } = useFileUploadForm()
const { showMessage } = useSimpleMessage()

watch(() => props.open, onToggle, { immediate: true })

function onToggle() {
  // Reset the form when the modal is opened
  if (props.open) reset()
}

async function clickSubmit() {
  const newFiles = await submit()
  if (!newFiles) return // Validation failed
  showMessage('Uploaded successfully')
  emit('filesCreated', newFiles)
}

function change(event: Event) {
  const target = event.target as HTMLInputElement
  const files = target.files
  if (!files || files.length === 0) return

  data.files = files
  data.fileNames = Array.from(files)
    .map((file) => file.name)
    .join(', ')
}
</script>

<template>
  <LargeModalLayout :open="open" :title="`Upload file${multiple ? 's' : ''}`" @close="$emit('close')">
    <template #content>
      <FormItem :label="`File${multiple ? 's' : ''}`" :error="errors.files">
        <input type="file" :multiple="multiple" @change="change" />
      </FormItem>

      <FormItem :label="`File name${multiple ? 's' : ''}`">
        <TextInput :model-value="data.fileNames" disabled />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button color="primary" variant="solid" @click="clickSubmit()">Submit</Button>
    </template>
  </LargeModalLayout>
</template>
