<script setup lang="ts">
import type { BlockItemFragment } from '@/generated/sdk'
import { SearchableList, TwinIcon } from '@/ui/components'
import { Button, Column, Dropdown } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { useBlockTypes } from '../composables'

const { blockTypes } = useBlockTypes()

const open = defineModel<boolean>('open')
const action = ref<'insertblock'>()

const emit = defineEmits<{
  selectBlockType: [blockItem: BlockItemFragment]
}>()

watch(open, () => (!open.value ? reset() : null), { immediate: true })

const listOptions = computed(() => {
  if (!blockTypes.value) return
  const items = blockTypes.value
    .map((blockType) => ({
      name: blockType.readableName,
      id: blockType.id,
      blockType: blockType.id,
      icon: blockType.icon,
    }))
    .sort((a, b) => {
      if (a.icon === b.icon) {
        return a.name.localeCompare(b.name)
      }
      return a.icon.localeCompare(b.icon)
    })
  return [
    {
      groupName: null,
      items,
    },
  ]
})

function reset() {
  action.value = undefined
  open.value = false
}

function selectBlockType(blockItemId: string) {
  const blockType = blockTypes.value?.find((blockType) => blockType.id === blockItemId)
  if (!blockType) return
  emit('selectBlockType', blockType)
  open.value = false
}
</script>

<template>
  <Dropdown v-model:open="open" @pointerdown.stop>
    <template #trigger>
      <slot>
        <button type="button">Show menu</button>
      </slot>
    </template>

    <template #content>
      <Column v-if="!action" gap="0">
        <Button color="primary" @click.stop="action = 'insertblock'">
          <TwinIcon icon="Plus" />
          Add new block
        </Button>
      </Column>

      <template v-if="action === 'insertblock'">
        <SearchableList
          header="Select block type"
          :grouped-options="listOptions"
          @close="reset"
          @select="selectBlockType"
        />
      </template>
    </template>
  </Dropdown>
</template>
