import { useApiClient } from '@/api'
import type { WorkflowItemFragment } from '@/generated/sdk'
import { ref } from 'vue'
import type { LocationQueryRaw } from 'vue-router'
import { useRoute } from 'vue-router'

const breadcrumbs = ref<WorkflowItemFragment[]>([])

export function useEditorBreadcrumbs(client = useApiClient().client, route = useRoute()) {
  // Store workflow ids in url query param `crumbs` and use it to show breadcrumbs
  const currentQuery = () => ({ crumbs: route.query.crumbs })

  function currentIds() {
    const ids = [route.query.crumbs ?? ''].flat().join(',').split(',')
    return ids.filter((x) => x)
  }

  function reset() {
    return { crumbs: undefined }
  }

  async function load() {
    // Load breadcrumbs from current route query parameter
    const ids = currentIds()
    const promises = ids.map((id) => client.workflows({ input: { id }, queryArgs: { size: 1 } }))
    const results = await Promise.all(promises)
    breadcrumbs.value = results.flatMap((result) => result.workflow)
  }

  function append(id: string): LocationQueryRaw {
    // Append subworkflow id to crumbs query parameter
    const crumbs = [...currentIds(), id].join(',')
    return { crumbs }
  }

  function popAfter(id: string): LocationQueryRaw {
    // Navigate to breadcrumb after removing all crumbs after the selected one
    const ids = currentIds()
    const index = ids.indexOf(id)
    if (index === 0) return { crumbs: undefined }
    const crumbs = index === 0 ? undefined : currentIds().slice(0, index).join(',')
    return { crumbs }
  }

  return { breadcrumbs, currentQuery, load, append, popAfter, reset }
}
