import { RequireAuth } from '@/auth/components'
import type { Plugin } from 'vue'
import type { Router } from 'vue-router'
import type { Navigation } from '../navigation'
import { CreditsNavItem, OrganizationNavItem, OrganizationPage, UserNavItem, UserSettingsPage } from './components'
import { userLinks, userRoutes } from './routes'

export const userPlugin: Plugin<{ router: Router; navigation: Navigation }> = {
  install: (_, options) => {
    // Register routes under a parent route with auth guard
    options.router.addRoute({
      name: 'user',
      path: '/user',
      component: RequireAuth,
      children: [
        { ...userRoutes.userSetting, component: UserSettingsPage },
        { ...userRoutes.organization, component: OrganizationPage },
      ],
      redirect: userLinks.userSetting(),
    })

    // Add separator before user nav entry
    options.navigation.navEntries.addNavEntry({
      isSeparator: true,
      position: 'end',
      order: 7,
    })

    // Add entry to navigation
    options.navigation.navEntries.addNavEntry({
      component: CreditsNavItem,
      position: 'end',
      order: 14,
    })
    options.navigation.navEntries.addNavEntry({
      component: OrganizationNavItem,
      position: 'end',
      order: 15,
    })
    options.navigation.navEntries.addNavEntry({
      component: UserNavItem,
      position: 'end',
      order: 16,
    })
  },
}
