import { useApiClient } from '@/api'
import type {
  WorkflowInputArgumentFragment,
  WorkflowInputArgumentInputSchema,
  WorkflowInputArgumentItemFragment,
} from '@/generated/sdk'
import { BlockArgumentInputType, BlockArgumentType } from '@/generated/sdk'
import { computed } from 'vue'
import { useWorkflowDetails } from '../composables'

export function useWorkflowSettings() {
  const { client } = useApiClient()
  const { workflow } = useWorkflowDetails()

  const workflowInputArguments = computed(
    () => workflow.value?.inputArguments.sort((a, b) => a.name.localeCompare(b.name)) || [],
  )

  async function createWorkflowArg(name: string) {
    const input = {
      workflow: { id: workflow.value?.id },
      name,
      description: '',
      argumentType: BlockArgumentType.String,
      inputType: BlockArgumentInputType.Text,
      required: false,
    }
    const result = await client.createWorkflowInputArgument({ input })
    workflow.value?.inputArguments.push(result.createWorkflowInputArgument)
    return result.createWorkflowInputArgument
  }

  async function createWorkflowArgItems(
    parent: WorkflowInputArgumentFragment,
    input: WorkflowInputArgumentInputSchema,
  ) {
    if (input.workflow) throw new Error('Should not be attached to workflow directly')
    const result = await client.createWorkflowInputArgument({ input })
    parent.items = result.createWorkflowInputArgument
    return parent.items
  }

  async function saveWorkflowArg(argument: WorkflowInputArgumentInputSchema) {
    if (!argument.id) return
    await client.updateWorkflowInputArgument({ input: argument })
  }

  async function deleteWorkflowArg(argument: WorkflowInputArgumentItemFragment) {
    if (!argument.id) return
    await client.deleteWorkflowInputArgument({ id: argument.id })
    if (workflow.value) {
      workflow.value.inputArguments = workflow.value?.inputArguments.filter((a) => a.id !== argument.id)
    }
  }

  return {
    workflow,
    workflowInputArguments,
    createWorkflowArg,
    createWorkflowArgItems,
    saveWorkflowArg,
    deleteWorkflowArg,
  }
}
