<script lang="ts" setup>
import { TypedInput } from '@/ui/components'
import TwinIcon from '@/ui/components/TwinIcon.vue'
import { Button, Column, FormItem, Row } from '@madxnl/dodo-ui'
import { type useRunWorkflowForm } from './useRunWorkflowForm'

const props = defineProps<{
  runForm: ReturnType<typeof useRunWorkflowForm>
}>()

const { inputNames, requiredArgNames, getArgError, getArgValue, setArgValue, getArgType } = props.runForm

function argIsArray(argName: string) {
  return getArgType(argName)?.argumentType === 'Array'
}

function forceArray(argName: string) {
  const value = getArgValue(argName)
  return Array.isArray(value) ? value : []
}

function addEntry(argName: string) {
  setArgValue(argName, [...forceArray(argName), null])
}

function remnoveEntry(argName: string, index: number) {
  const array = forceArray(argName)
  array.splice(index, 1)
  setArgValue(argName, array)
}

function setArrayEntry(argName: string, index: number, value: unknown) {
  const array = forceArray(argName)
  array[index] = value
  setArgValue(argName, array)
}

function getArrayType(argName: string) {
  return getArgType(argName)?.items
}
</script>

<template>
  <Column>
    <template v-for="argName of inputNames" :key="argName">
      <FormItem :label="argName" :error="getArgError(argName)" :optional="!requiredArgNames.includes(argName)">
        <template v-if="argIsArray(argName)">
          <Row v-for="(value, index) in forceArray(argName)" :key="index">
            <Row style="flex: 1">
              <TypedInput
                :type="getArrayType(argName)"
                :model-value="value"
                @update:model-value="(value) => setArrayEntry(argName, index, value ?? '')"
              />
            </Row>
            <Button variant="clear" square title="Delete entry" @click="remnoveEntry(argName, index)">
              <TwinIcon icon="Delete" size="s" />
            </Button>
          </Row>
          <Row justify="end">
            <Button variant="clear" size="s" title="Add entry" @click="addEntry(argName)">
              <TwinIcon icon="Plus" size="s" />
            </Button>
          </Row>
        </template>

        <TypedInput
          v-else
          :type="getArgType(argName)"
          :model-value="getArgValue(argName)"
          @update:model-value="(value) => setArgValue(argName, value ?? '')"
        />
      </FormItem>
    </template>
  </Column>
</template>
