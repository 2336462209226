import { useApiClient } from '@/api'
import type { LoginMutationVariables } from '@/generated/sdk'
import { computed, reactive } from 'vue'

type Tokens = {
  token: string
  refreshToken: string
  tokenExpirationDateUTC: string
  refreshExpirationDateUTC: string
}

const state = reactive({
  refreshTimeout: null as ReturnType<typeof setTimeout> | null,
  initPromise: null as Promise<void> | null,
  tokens: null as Tokens | null,
})

const localStorageKey = 'auth-tokens'

const isAuthenticated = computed(() => !!state.tokens?.token)
const accessToken = computed(() => state.tokens?.token)

export function useAuthSession() {
  const api = useApiClient()

  async function loadAuthSession(opts?: { reset: boolean }) {
    if (opts?.reset) state.initPromise = null
    // Wait for the auth session to be loaded, if it's not already
    state.initPromise = state.initPromise ?? loadAuthAsync()
    await state.initPromise
    return isAuthenticated.value
  }

  function parseStoredTokens() {
    const json = localStorage.getItem(localStorageKey)
    const tokens: unknown = JSON.parse(json ?? '{}')
    if (!tokens || typeof tokens !== 'object') return
    type Key = keyof typeof tokens
    const token = String(tokens['token' as Key])
    const refreshToken = String(tokens['refreshToken' as Key])
    const tokenExpirationDateUTC = String(tokens['tokenExpirationDateUTC' as Key])
    const refreshExpirationDateUTC = String(tokens['refreshExpirationDateUTC' as Key])
    return { token, refreshToken, tokenExpirationDateUTC, refreshExpirationDateUTC }
  }

  async function loadAuthAsync() {
    // Restore the auth session from the storage
    const tokens = parseStoredTokens()
    if (!tokens) return
    setTokens(tokens)
    await refreshAuth()
    scheduleRefresh()
  }

  function setTokens(tokens: Tokens) {
    const { token, refreshToken, tokenExpirationDateUTC, refreshExpirationDateUTC } = tokens
    api.setAccessToken(token)
    state.tokens = { token, refreshToken, tokenExpirationDateUTC, refreshExpirationDateUTC }
    localStorage.setItem(localStorageKey, JSON.stringify(state.tokens))
  }

  const refreshTimerInterval = 10 * 1000

  function scheduleRefresh() {
    // Refresh then schedule the next refresh
    if (!isAuthenticated.value) return
    refreshAuth().catch(() => {})
    if (state.refreshTimeout) clearTimeout(state.refreshTimeout)
    state.refreshTimeout = setTimeout(scheduleRefresh, refreshTimerInterval)
  }

  async function refreshAuth() {
    // Refresh token if it is about to expire in the next interval
    const tokenExpirationDate = new Date(state.tokens!.tokenExpirationDateUTC)
    const expirationTime = tokenExpirationDate.getTime()
    if (isNaN(expirationTime)) return
    const timeUntilExpire = expirationTime - Date.now()
    if (timeUntilExpire < refreshTimerInterval + 1000) {
      try {
        const refreshToken = state.tokens?.refreshToken
        if (!refreshToken) throw new Error('No refresh token')
        const response = await api.client.refresh({ refreshToken })
        setTokens(response.refresh)
      } catch (e) {
        logoutAuth()
      }
    }
  }

  async function login(data: LoginMutationVariables) {
    const response = await api.client.login(data)
    setTokens(response.login)
    scheduleRefresh()
    return isAuthenticated.value
  }

  function logoutAuth() {
    // Sign out from the API
    if (isAuthenticated.value) {
      api.client.logout().catch(() => {})
    }
    // Remove the tokens from the storage
    setTokens({ token: '', refreshToken: '', tokenExpirationDateUTC: '', refreshExpirationDateUTC: '' })
  }

  return {
    login,
    logoutAuth,
    isAuthenticated,
    loadAuthSession,
    accessToken,
  }
}
