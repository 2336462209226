import { RequireAuth } from '@/auth/components'
import { WorkflowBlockDataPage } from '@/workflows/datapage'
import type { Plugin } from 'vue'
import type { Router } from 'vue-router'
import type { Navigation } from '../navigation'
import EditorPage from './EditorPage.vue'
import ProvideWorkflow from './ProvideWorkflow.vue'
import { editorRoutes } from './routes'

export const workflowEditPlugin: Plugin<{ router: Router; navigation: Navigation }> = {
  install: (_, options) => {
    // Register editor routes under a parent route with auth guard
    options.router.addRoute({
      name: 'editor',
      path: '/editor',
      component: RequireAuth,
      children: [
        {
          path: 'edit/:workflowId',
          props: true,
          component: ProvideWorkflow,
          children: [
            { ...editorRoutes.workflowEdit, component: EditorPage },
            { ...editorRoutes.reviewData, component: WorkflowBlockDataPage },
          ],
        },
      ],
      redirect: '/',
    })
  },
}
