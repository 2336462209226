<script lang="ts" setup>
import type { ColorProp } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import * as Icons from '../icons'

export type TwinIconName = keyof typeof Icons

const props = defineProps<{
  icon: TwinIconName
  color?: ColorProp
  size?: 's' | 'l'
  noFill?: boolean
}>()

const svg = computed(() => Icons[props.icon])
</script>

<template>
  <span
    :class="[
      $style.twinicon,
      color && `dodo-color-${color}`,
      size === 's' && $style.s,
      size === 'l' && $style.l,
      !noFill && $style.fill,
    ]"
    v-html="svg"
  ></span>
</template>

<style module>
.twinicon {
  display: flex;
  vertical-align: middle;
  overflow: hidden;
  width: 20px;
  height: 20px;
}
.twinicon svg {
  width: 100%;
  height: 100%;
}
.twinicon.fill * {
  fill: currentColor;
}
.twinicon.s {
  width: 16px;
  height: 16px;
}
.twinicon.l {
  width: 24px;
  height: 24px;
}
</style>
