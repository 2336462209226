import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { nextTick } from 'vue'
import { useEditorState } from './useEditorState'
import { useEditorView } from './useEditorView'
import { useWorkflowDetails } from './useWorkflowDetails'
import { useWorkflowEditor } from './useWorkflowEditor'

function simplifyGraph(workflowBlocks: WorkflowBlockItemFragment[]) {
  // Returns a block graph with no cycles in it for visual layout
  const connected = new Set<WorkflowBlockItemFragment>()
  const edges = new Map<WorkflowBlockItemFragment, WorkflowBlockItemFragment[]>()
  for (const block of workflowBlocks) {
    const blockEdges = edges.get(block) ?? []
    edges.set(block, blockEdges)
    for (const id of block.nextBlocks ?? []) {
      const nextBlock = workflowBlocks.find((b) => b.id === id.id)
      if (!nextBlock || connected.has(nextBlock)) continue
      connected.add(nextBlock)
      blockEdges.push(nextBlock)
    }
    if (block.blockType === WorkflowBlockType.IfElse) {
      // Sort child blocks for 'true' before 'false'
      blockEdges.sort((a, b) => -(a.condition?.localeCompare(b.condition ?? '') ?? 0))
    } else {
      // Sort child blocks by condition alphabetically
      blockEdges.sort((a, b) => a.condition?.localeCompare(b.condition ?? '') ?? 0)
    }
  }
  return edges
}

export function useWorkflowAutoLayout() {
  const { workflow, triggerSaveWorkflow } = useWorkflowDetails()
  const { editorBlocks, gridSize } = useWorkflowEditor()
  const { setBlockPosition } = useEditorState()
  const { centerView } = useEditorView()

  async function recalculateLayout() {
    if (!workflow.value) return
    const positions = computeBlockPositions()
    for (const [b, p] of positions.entries()) {
      setBlockPosition(b, [p.x, p.y])
    }
    await nextTick()
    centerView()
    triggerSaveWorkflow()
  }

  function getBlockComponent(id: string) {
    return editorBlocks.value.find((b) => b.id === id)
  }

  function computeBlockPositions() {
    // Compute a graph layout while ignoring any cycles
    const workflowBlocks = workflow.value?.workflowBlocks ?? []
    const edges = simplifyGraph(workflowBlocks)
    // const nonRootIds = new Set(Array.from(edges.values()).flatMap((e) => e.map((x) => x.id)))
    const nonRootIds = workflowBlocks.flatMap((block) => block.nextBlocks?.map((x) => x.id))
    const roots = workflowBlocks.filter((b) => !nonRootIds.includes(b.id))
    const [gapX, gapY] = [gridSize, gridSize * 2]
    const positions = new Map<string, { x: number; y: number }>()
    const graphDims = new Map<WorkflowBlockItemFragment, { width: number; height: number }>()

    function getBlockSize(workflowBlock: WorkflowBlockItemFragment) {
      // Compute the dimensions of the block for layout purposes
      const size = getBlockComponent(workflowBlock.id)?.size()
      if (!size) return { blockWidth: 0, blockHeight: 0 }
      const blockHeight = Math.ceil(size.height / gridSize) * gridSize
      const blockWidth = Math.ceil(size.width / gridSize) * gridSize
      return { blockWidth, blockHeight }
    }

    function getGraphDimensions(workflowBlock: WorkflowBlockItemFragment) {
      // Compute the dimensions of the graph rooted at block
      let dims = graphDims.get(workflowBlock)
      if (!dims) {
        const childGraphSizes = edges.get(workflowBlock)?.map((c) => getGraphDimensions(c)) ?? []
        const { blockHeight, blockWidth } = getBlockSize(workflowBlock)
        const childrenTotalWidth = childGraphSizes.reduce((s, x) => s + x.width + gapX, -gapX)
        const childrenMaxHeight = Math.max(...childGraphSizes.map((x) => x.height), 0)
        let width = Math.max(blockWidth, childrenTotalWidth)
        if (workflowBlock.blockType === WorkflowBlockType.Switch) {
          width = blockWidth + childrenTotalWidth + gapX
        }
        const height = blockHeight + gapY + childrenMaxHeight
        dims = { width, height }
        graphDims.set(workflowBlock, dims)
      }
      return dims
    }

    function updatePositionsDown(workflowBlock: WorkflowBlockItemFragment, left: number, top: number) {
      // Update positions of block and its children
      const { blockHeight, blockWidth } = getBlockSize(workflowBlock)
      const graphDims = getGraphDimensions(workflowBlock)
      const isSwitch = workflowBlock.blockType === WorkflowBlockType.Switch
      const centerX = Math.floor((left + graphDims.width / 2 - blockWidth / 2) / gridSize) * gridSize
      const bx = isSwitch ? left : centerX
      positions.set(workflowBlock.id, { x: bx, y: top })
      const childY = top + blockHeight + gapY
      let childX = isSwitch ? bx + blockWidth + gapX : left
      for (const child of edges.get(workflowBlock) ?? []) {
        updatePositionsDown(child, childX, childY)
        const childGraphWidth = getGraphDimensions(child).width
        childX += childGraphWidth + gapX
      }
    }

    let x = 0
    for (const root of roots) {
      const size = getGraphDimensions(root)
      const y = 0
      updatePositionsDown(root, x, y)
      x += size.width + gapX
    }
    return positions
  }

  return {
    recalculateLayout,
  }
}
