import { useApiClient } from '@/api'
import type { SettingsField } from '@/workflow-edit'
import { useFieldGeneration } from '@/workflow-edit'
import { useBlockSettingsContext } from '@/workflow-edit/sidebar/useBlockSettingsContext'
import { ref, watch } from 'vue'

export function useRunBlockConfigForm() {
  const { client } = useApiClient()
  const { generatedFields } = useFieldGeneration()
  const { blockConfig } = useBlockSettingsContext()

  const latestInput = ref<unknown>()

  watch(
    blockConfig,
    async () => {
      if (!blockConfig.value) return
      const response = await client.getBlockConfigOutput({
        blockConfigId: blockConfig.value.id,
        runArgs: { size: 1, page: 1 },
      })
      latestInput.value = response.blockConfig[0]?.runs[0]?.input
    },
    { immediate: true },
  )

  watch(latestInput, () => {
    if (!latestInput.value || typeof latestInput.value !== 'object') return
    const input = latestInput.value as Record<string, unknown>

    generatedFields.value.forEach((field) => {
      const argName = field.namePath.join('.')
      if (argName in input) {
        field.data = input[argName]
      }
    })
  })

  function setFieldData(field: SettingsField, value: unknown) {
    field.data = value
  }

  async function submitRun() {
    const input = generatedFields.value.reduce(
      (acc, field) => {
        acc[field.namePath.join('.')] = field.data
        return acc
      },
      {} as Record<string, unknown>,
    )

    await client.runByBlockConfigId({
      blockConfigId: blockConfig.value!.id,
      input,
    })
  }

  return {
    generatedFields,
    submitRun,
    setFieldData,
  }
}
